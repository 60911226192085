$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;
@use '../../../styles/colors' as colors;

section.n4-cta {
  position: relative;
  display: flex;
  flex-direction: column;
  //margin: 5rem 0 2rem 0;

  .sponsoredLabel {
    display: none;
  }

  @media #{variables.$xs} {
    margin: 3rem 0;
  }

  figure.image {
    position: relative;
    width: 100%;
    height: 45rem;
    display: flex;
    border-radius: 1.25rem;
    background-color: lightgray;
    overflow: hidden;
    margin: 0;

    @media #{variables.$xs} {
      max-height: 37.5rem;
    }

    img {
      object-fit: cover;
    }
  }

  section.content,
  a.content {
    border-radius: 1.25rem;
    overflow: hidden;
    position: absolute;
    justify-content: space-between;
    height: 45rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 3rem;
    align-self: stretch;
    z-index: 2;

    @media #{variables.$xs} {
      height: 100%;
      padding: 1rem;
      flex-direction: column;
    }

    span.top-title {
      color: colors.$white;
      font-family: var(--hankenGrotesk-font);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: -0.01rem;
      max-width: 48rem;
      display: flex;
      justify-content: flex-start;

      @media #{variables.$xs} {
        padding: 1rem 0 0 1rem;
      }
    }

    section.content-data {
      align-self: stretch;
      display: flex;
      max-width: 35rem;
      width: 100%;
      padding: 2rem;
      flex-direction: column;
      justify-items: flex-end;
      height: fit-content;
      gap: 2rem;
      border-radius: 1.25rem;
      background-color: colors.$white;

      @media #{variables.$xs} {
        display: flex;
        padding: 1.5rem;
        flex-direction: column;
        align-self: center;
        gap: 2rem;
        justify-content: space-between;
        max-width: unset;
      }

      @media #{variables.$sm} {
        align-self: flex-start;
      }

      section.about {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        align-self: stretch;

        @media #{variables.$xs} {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          align-self: stretch;
        }

        span.title {
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 3rem;
          font-style: normal;
          font-weight: 450;
          line-height: 3.6rem;
          letter-spacing: -0.03rem;

          @media #{variables.$xs} {
            font-size: 2rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.4rem;
            letter-spacing: -0.02rem;
          }
        }

        p.description {
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.01125rem;
          max-width: 30rem;
        }
      }

      section.action {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;

        span {
          color: colors.$navy-blue;
          font-family: var(--hankenGrotesk-font);
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 330;
          line-height: 1.6875rem;
          letter-spacing: -0.01125rem;

          @media #{variables.$xs} {
            font-size: 1rem;
            font-style: normal;
            font-weight: 330;
            line-height: 1.5rem;
            letter-spacing: -0.01rem;
          }
        }

        section.offer {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          width: 3.625rem;
          height: 3.625rem;
          background: colors.$navy-blue;

          i {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 1rem;
            height: 1.188rem;
            filter: invert(95%) sepia(100%) saturate(0%) hue-rotate(78deg) brightness(104%) contrast(106%);
          }
        }
      }
    }
  }

  // Based on blok.card_position
  // TODO: abram might want to adjust these positions
  section.content-left,
  a.content-left,
  section.content-split-left,
  a.content-split-left {
    max-width: 48rem;
  }

  section.content-right,
  section.content-split-right,
  a.content-right,
  a.content-split-right {
    span.top-title {
      position: aboslute;
      left: 2rem;
      top: 2rem;
    }

    section.content-data {
      border: 1px sollid green;
      position: absolute;
      right: 2rem;
      bottom: 2rem;
    }
  }
}

// TODO: this is the split cta with green-ish background on the right
// brian styled this it's probably all wrong.
section.cta-split-right {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;

  figure {
    position: relative;
    flex-grow: 3;
  }

  section.content-split-right,
  a.content-split-right {
    flex-shrink: 2;
    background: #e1f7c6;
    position: relative;

    section.content-data {
      background: #e1f7c6;
      top: 2rem;
      left: 2rem;
      height: 40rem;
    }

    section.action {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }
  }
}

.home-call-to-action {
  margin: 0 0 0 0 !important;

  &.cta-split-right {
    position: relative;
    display: flex;
    padding: 0px 48px 32px 48px;
    gap: 16px !important;
    cursor: default;
    margin: 0 !important;

    @media #{variables.$xs} {
      padding: 0px 16px 24px 16px !important;
      gap: 8px !important;
      flex-direction: column !important;
    }

    @media #{variables.$desktop-wide} {
      padding: 0px 96px 32px 96px !important;
      gap: 32px !important;
    }

    .top-title {
      display: none !important;
    }

    figure.image {
      position: relative;
      height: 600px;
      max-width: 1095 !important;
      min-width: 890px !important;
      display: flex !important;
      border-radius: 20px !important;
      background-color: lightgray;
      overflow: hidden;
      margin: 0 !important;

      @media #{variables.$xs} {
        height: 280px !important;
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
      }

      @media #{variables.$md, variables.$lg, variables.$lg-to-desktop} {
        min-width: 60% !important;
        width: 60%;
      }

      @media #{variables.$above-xs} {
        min-width: 55% !important;
        width: 55%;
      }

      @media #{variables.$desktop-wide} {
        width: 1300px !important;
        max-width: 1300px !important;
        min-width: 40% !important;
        height: 800px !important;
        flex-shrink: 0 !important;
        border-radius: 30px !important;
      }

      @media #{variables.$desktop-wide-md} {
        max-width: 60% !important;
      }

      img {
        object-fit: cover;
      }
    }

    .content-data {
      display: flex;
      position: relative !important;
      padding: 64px 0px 0px 0px !important;
      gap: 0 !important;
      top: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      right: 0 !important;
      height: 100% !important;
      border: none !important;
      max-width: 100% !important;
      justify-content: space-between !important;
      align-items: flex-start;
      flex: 1 0 0;

      @media #{variables.$desktop-wide} {
        padding: 96px 0px 0px 0px !important;
      }

      @media #{variables.$xs} {
        padding: 48px 0px 0px 0px !important;
      }
    }

    section.content,
    a.content {
      border-radius: 20px;
      height: 600px;
      min-width: 400px;
      max-width: 528px;
      align-items: flex-start;
      padding: 24px;

      @media #{variables.$xs} {
        min-width: 100%;
        max-width: 100%;
        height: 360px;
      }

      @media #{variables.$above-xs} {
        min-width: 35% !important;
      }

      @media #{variables.$md, variables.$lg, variables.$lg-to-desktop} {
        min-width: 30%;
      }

      @media #{variables.$desktop-wide-md} {
        min-width: 30% !important;
      }

      @media #{variables.$desktop-wide} {
        border-radius: 30px;
        height: 800px;
        padding: 35px;
        min-width: 586px;
        max-width: 774px;
      }

      @media only screen and (min-width: 2230px) {
        max-width: 100% !important;
      }
    }

    section.about {
      width: 100%;
      overflow: hidden;
      gap: 0 !important;

      @media #{variables.$md, variables.$lg, variables.$lg-to-desktop} {
        width: 102% !important;
      }
    }

    span.title {
      font-size: 20px !important;
      font-weight: variables.$font-weight-semibold !important;
      line-height: 120% !important;
      letter-spacing: -0.6px !important;
      margin-bottom: 16px !important;

      @media #{variables.$xs} {
        margin-bottom: 12px !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 140% !important;
        letter-spacing: -0.48px !important;
      }

      @media #{variables.$desktop-wide} {
        margin-bottom: 24px !important;
        font-size: 30px !important;
        letter-spacing: -0.879px !important;
      }
    }

    p.description {
      font-size: 40px !important;
      font-weight: variables.$font-weight-semibold !important;
      line-height: 120% !important;
      letter-spacing: -1.2px !important;

      @media #{variables.$xs} {
        font-size: 32px !important;
        letter-spacing: -0.96px !important;
        margin-bottom: 50px !important;
      }

      @media #{variables.$desktop-wide} {
        font-size: 59px !important;
        letter-spacing: -1.757px !important;
      }
    }

    .action {
      gap: 16px !important;
      align-self: stretch;
      right: 0px !important;
      bottom: 0px !important;

      @media #{variables.$desktop-wide} {
        gap: 23px !important;
      }

      span {
        font-size: 18px !important;
        font-weight: 500 !important;
        line-height: 120% !important;
        letter-spacing: -0.54px !important;

        @media #{variables.$xs} {
          font-size: 16px !important;
          line-height: 140% !important;
          letter-spacing: -0.48px !important;
        }

        @media #{variables.$desktop-wide} {
          font-size: 27px !important;
          letter-spacing: -0.791px !important;
        }
      }

      .offer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 60px !important;
        padding: 16px !important;
        gap: 8px !important;
        width: 56px !important;
        height: 56px !important;
        box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.15) !important;
        cursor: pointer !important;

        @media #{variables.$xs} {
          border-radius: 51px !important;
          padding: 14px !important;
          gap: 7px !important;
          width: 48px !important;
          height: 48px !important;
        }

        i {
          width: 24px !important;
          height: 24px !important;

          @media #{variables.$xs} {
            width: 20.6px !important;
            height: 20.6px !important;
          }
        }
      }
    }

    @media #{variables.$desktop-wide} {
      section.content,
      a.content {
        .offer {
          border-radius: 88px !important;
          padding: 23px !important;
          gap: 12px !important;
          width: 82px !important;
          height: 82px !important;
          box-shadow: 5.858px 5.858px 46.862px -2.929px rgba(0, 0, 0, 0.15) !important;

          i {
            width: 35px !important;
            height: 35px !important;
          }
        }
      }
    }

    .sponsoredLabel {
      display: flex !important;
      padding: 8px 16px;
      height: 36px;
      width: 97px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 60px;
      background-color: #b9d5ff;
      font-size: 14px;
      color: colors.$navy-blue;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.42px;
      margin-bottom: 32px !important;
      border: none !important;
      position: absolute;
      z-index: 1;

      @media #{variables.$xs} {
        margin-bottom: 16px !important;
      }

      @media #{variables.$desktop-wide} {
        padding: 12px 24px;
        gap: 12px;
        width: 142px;
        height: 52px;
        margin-bottom: 47px !important;
        font-size: 21px;
        letter-spacing: -0.615px;
        border-radius: 88px !important;
      }
    }
  }

  .content-left,
  .content-right {
    display: flex !important;
    height: 720px !important;
    width: 100% !important;
    padding: 48px !important;
    border-radius: 20px !important;

    @media only screen and (max-width: 768px) {
      padding: 16px;
      height: 600px;
      width: calc(100% - 32px);
    }

    .top-title {
      display: flex !important;
    }

    .sponsoredLabel {
      display: none !important;
    }
  }

  &.cta-left {
    padding: 0px 48px 32px 48px !important;

    @media #{variables.$xs} {
      padding: 0px 16px 24px 16px !important;
    }

    @media #{variables.$desktop-wide} {
      padding: 0px 96px 32px 96px !important;
    }

    .image {
      height: 720px !important;
      min-height: 720px !important;

      img {
        object-fit: cover;
        object-position: top !important;
      }

      @media #{variables.$xs} {
        height: 600px !important;
        min-height: 600px !important;
      }

      @media #{variables.$desktop-wide} {
        height: 800px !important;
        max-height: 100vh;
      }
    }

    .content-left {
      align-items: flex-start;
      max-width: calc(100% - 96px) !important;
      min-width: calc(100% - 96px) !important;
      height: 720px !important;

      @media #{variables.$xs} {
        height: 600px !important;
        width: calc(100% - 32px) !important;
        max-width: calc(100% - 32px) !important;
        min-width: calc(100% - 32px) !important;
        max-height: 900px !important;
        padding: 16px !important;
      }

      @media #{variables.$desktop-wide} {
        max-width: calc(100% - 192px) !important;
        min-width: calc(100% - 192px) !important;
        padding: 57px !important;
        height: 800px !important;
        max-height: 100vh;
      }

      .top-title {
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 140% !important;
        letter-spacing: -0.48px !important;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        @media #{variables.$desktop-wide} {
          text-shadow: 0px 4.801px 4.801px rgba(0, 0, 0, 0.25);
          font-size: 19px !important;
          letter-spacing: -0.576px !important;
        }

        @media #{variables.$xs} {
          padding: 12px !important;
          text-shadow: none !important;
        }
      }

      .content-data {
        display: flex !important;
        width: 560px !important;
        padding: 32px !important;
        flex-direction: column !important;
        align-items: flex-end !important;
        gap: 32px !important;
        border-radius: 20px !important;

        @media #{variables.$xs} {
          align-self: flex-start !important;
          width: 100% !important;
          max-width: 100% !important;
          padding: 24px 25px !important;
        }

        @media #{variables.$desktop-wide} {
          width: 672px !important;
          max-width: 672px !important;
          border-radius: 24px !important;
          padding: 38px !important;
          gap: 38px !important;
        }

        .about {
          gap: 16px !important;

          @media #{variables.$desktop-wide} {
            gap: 19px !important;
          }
        }

        .title {
          width: 100%;
          font-size: 48px !important;
          font-weight: variables.$font-weight-semibold !important;
          line-height: 110% !important;
          letter-spacing: -1.44px !important;
          overflow: hidden;

          @media #{variables.$xs} {
            font-size: 32px !important;
            line-height: 120% !important;
            letter-spacing: -0.96px !important;
          }

          @media #{variables.$desktop-wide} {
            font-size: 57px !important;
            line-height: 110% !important;
            letter-spacing: -1.728px !important;
          }
        }

        .description {
          width: 100%;
          max-width: 100% !important;
          font-size: 18px !important;
          font-weight: variables.$font-weight-normal !important;
          line-height: 140% !important;
          letter-spacing: -0.54px !important;
          overflow: hidden;

          @media #{variables.$desktop-wide} {
            font-size: 21px !important;
            letter-spacing: -0.648px !important;
          }
        }

        .action {
          gap: 16px !important;

          @media #{variables.$desktop-wide} {
            gap: 19px !important;
          }

          span {
            font-size: 18px !important;
            font-weight: variables.$font-weight-normal !important;
            line-height: 140% !important;
            letter-spacing: -0.54px !important;

            @media #{variables.$xs} {
              font-size: 16px !important;
              font-weight: 500 !important;
              letter-spacing: -0.48px !important;
            }

            @media #{variables.$desktop-wide} {
              font-size: 21px !important;
              letter-spacing: -0.648px !important;
            }
          }

          .offer {
            display: flex;
            padding: 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 60px;
            width: 56px;
            height: 56px;
            box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.15);

            @media #{variables.$xs} {
              width: 48px;
              height: 48px;
              padding: 13px;
              gap: 6px;
              border-radius: 51px;
            }

            i {
              width: 24px;
              height: 24px;

              @media #{variables.$xs} {
                width: 20px;
                height: 20px;
                flex-shrink: 0;
              }
            }
          }
        }
      }
    }
  }

  &.cta-right {
    padding: 48px 48px 8px 48px !important;

    @media #{variables.$xs} {
      padding: 48px 16px 0px 16px !important;
    }

    @media #{variables.$desktop-wide} {
      padding: 48px 96px 0px 96px !important;
    }

    .image {
      height: 720px !important;
      min-height: 720px !important;

      @media #{variables.$xs} {
        height: 600px !important;
        min-height: 600px !important;
      }

      @media #{variables.$desktop-wide} {
        height: 800px !important;
        min-height: 800px !important;
      }
    }

    .content-right {
      align-items: flex-start;
      max-width: calc(100% - 96px) !important;
      min-width: calc(100% - 96px) !important;
      height: 720px !important;

      @media #{variables.$xs} {
        height: 600px !important;
        width: calc(100% - 32px) !important;
        max-width: calc(100% - 32px) !important;
        min-width: calc(100% - 32px) !important;
        max-height: 900px !important;
        padding: 16px !important;
      }

      @media #{variables.$desktop-wide} {
        max-width: calc(100% - 192px) !important;
        min-width: calc(100% - 192px) !important;
        padding: 57px !important;
        height: 800px !important;
      }

      .top-title {
        font-size: 16px !important;
        font-weight: 500 !important;
        line-height: 140% !important;
        letter-spacing: -0.48px !important;
        text-shadow: none !important;

        @media #{variables.$xs} {
          padding: 12px !important;
        }
      }

      .content-data {
        display: flex !important;
        width: 560px !important;
        padding: 32px !important;
        flex-direction: column !important;
        align-items: flex-end !important;
        gap: 32px !important;
        border-radius: 20px !important;
        right: 48px;
        bottom: 48px;

        @media #{variables.$xs} {
          width: calc(100% - 32px) !important;
          max-width: calc(100% - 32px) !important;
          padding: 24px 25px !important;
          right: 16px;
          bottom: 16px;
        }

        .about {
          gap: 16px !important;
        }

        .title {
          width: 100%;
          font-size: 48px !important;
          font-weight: variables.$font-weight-semibold !important;
          line-height: 110% !important;
          letter-spacing: -1.44px !important;
          overflow: hidden;

          @media #{variables.$xs} {
            font-size: 32px !important;
            line-height: 120% !important;
            letter-spacing: -0.96px !important;
          }
        }

        .description {
          width: 100%;
          max-width: 100% !important;
          font-size: 18px !important;
          font-weight: variables.$font-weight-normal !important;
          line-height: 140% !important;
          letter-spacing: -0.54px !important;
          overflow: hidden;
        }

        .action {
          gap: 16px !important;

          span {
            font-size: 18px !important;
            font-weight: variables.$font-weight-normal !important;
            line-height: 140% !important;
            letter-spacing: -0.54px !important;

            @media #{variables.$xs} {
              font-size: 16px !important;
              font-weight: 500 !important;
              letter-spacing: -0.48px !important;
            }
          }

          .offer {
            display: flex;
            padding: 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 60px;
            width: 56px;
            height: 56px;
            box-shadow: 4px 4px 32px -2px rgba(0, 0, 0, 0.15);

            @media #{variables.$xs} {
              width: 48px;
              height: 48px;
              padding: 13px;
              gap: 6px;
              border-radius: 51px;
            }

            i {
              width: 24px;
              height: 24px;

              @media #{variables.$xs} {
                width: 20px;
                height: 20px;
                flex-shrink: 0;
              }
            }
          }
        }
      }
    }
  }
}
