$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;
@use '../../../styles/colors' as colors;

/* 
  Idea here is to use em instead of rem so default will inherit the parent,
  but small will be relative to the parent. Otherwise, we could end up with
  small being bigger than the parent, like if the parent was smaller than 
  normal.
  May need to adjust the headers.
*/

section.markdown {
  strong,
  b {
    font-weight: 500;
    @media #{variables.$xs} {
      font-weight: 600;
    }
  }

  ul li,
  ol li {
    font-weight: 300;
    line-height: 1.7;
    margin-bottom: 1rem;

    @media #{variables.$xs} {
      font-weight: 400;
    }
  }
  ul,
  ol {
    margin: 1.5rem 1rem 1.5rem 1.6rem;
    li {
      padding-left: 0.3rem;
    }
  }
  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }

  ul li {
    font-size: 1.3rem; // same as parent
    list-style-type: disc;
  }
  ol li {
    list-style-type: decimal;
  }

  &--author-bio {
    p {
      font-size: 1.1rem;
      @media #{variables.$xs} {
        font-size: 1.2rem;
      }
    }
  }

  &--small {
    &:first-child {
      margin-top: 1.5rem;
    }
    h1,
    h1 strong {
      font-size: 2rem !important;
    }

    h2,
    h2 strong {
      font-size: 1.8rem !important;
    }

    h3,
    h3 strong {
      font-size: 1.6rem !important;
    }

    h4,
    h4 strong,
    h5,
    h5 strong,
    h6,
    h6 strong {
      font-size: 1.4rem !important;
    }
    ul li,
    ol li {
      font-size: 1rem !important;
      font-weight: 350;
    }
    ul,
    ol {
      margin: 0.65rem !important;
    }
    p {
      font-size: 1rem !important;
    }
  }
}

// These are from ads injected into the middle of a markdown blok
// I copied these styles from n4/image, but the class is on the section
// on that one versus the actual <a> tag here.
a.desktop-ad-auto,
a.desktop-ad-auto-no-border {
  border-radius: 1.25rem;
  margin: 2rem 0;
  &::before {
    content: 'ADVERTISEMENT';
    display: block;
    text-align: center;
    letter-spacing: 0.8px;
    font-size: 9px; /* Adjust font size as needed */
    color: #ccc; /* Adjust text color as needed */
    font-family: var(--hankenGrotesk-font);
    padding: 2px 4px; /* Adjust padding as needed */
    z-index: 1;
  }
  display: block;
  img {
    border-radius: 11px !important;
  }
  @media #{variables.$xs} {
    display: none;
  }
}

a.desktop-ad-auto-no-border img {
  border-radius: 0px !important;
}

a.mobile-ad-auto,
a.mobile-ad-auto-no-border {
  border-radius: 1.25rem;
  margin: 3rem 0;
  &::before {
    content: 'ADVERTISEMENT';
    display: block;
    text-align: center;
    letter-spacing: 0.8px;
    font-size: 9px; /* Adjust font size as needed */
    color: #ccc; /* Adjust text color as needed */
    font-family: var(--hankenGrotesk-font);
    padding: 2px 4px; /* Adjust padding as needed */
    z-index: 1;
  }
  display: block;
  img {
    border-radius: 11px !important;
    &.no-rounded {
      border-radius: 0px !important;
    }
  }
  @media #{variables.$above-md} {
    display: none;
  }
}

a.mobile-ad-auto-no-border img {
  border-radius: 0px !important;
}
